import {
    getCurrencyFormatter,
    getPercentageFormatter,
} from "../../services/util.js";
class {
    onCreate(input) {
        this.state = {
            topItems: input.items.topItems,
            flopItems: input.items.flopItems,
            currencyFormatter: getCurrencyFormatter(
                input.locale,
                input.currency,
                input.currencyDecimals,
            ),
            percentageFormatter: getPercentageFormatter(input.locale),
        };
    }
}

<if(state.topItems.length > 0)>
    <h2>Tops</h2>
    <table>
        <th class="name">
            name
        </th>
        <th class="value last-year">
            value last year
        </th>
        <th class="value today">
            value today
        </th>
        <th class="change">
            % change
        </th>
        <for|item, index| of=state.topItems>
            <tr>
                <td class="name">
                    <a href=`/item/${item.name}`>
                        ${item.name}
                    </a>
                </td>
                <td class="value last-year">
                    ${state.currencyFormatter.format(item.avg_value_last_year)}
                </td>
                <td class="value today">
                    ${state.currencyFormatter.format(item.avg_value_today)}
                </td>
                <td class=[
                    "change",
                    {
                        profit: item.percentage_difference > 0,
                        loss: item.percentage_difference < 0,
                    },
                ]>
                    ${state.percentageFormatter.format(
                        item.percentage_difference / 100,
                    )}
                </td>
            </tr>
        </for>
    </table>
</if>
<if(state.flopItems.length > 0)>
    <h2>Flops</h2>
    <table>
        <th class="name">
            name
        </th>
        <th class="value last-year">
            value last year
        </th>
        <th class="value today">
            value today
        </th>
        <th class="change">
            % change
        </th>
        <for|item, index| of=state.flopItems>
            <tr>
                <td class="name">
                    <a href=`/item/${item.name}`>
                        ${item.name}
                    </a>
                </td>
                <td class="value last-year">
                    ${state.currencyFormatter.format(item.avg_value_last_year)}
                </td>
                <td class="value today">
                    ${state.currencyFormatter.format(item.avg_value_today)}
                </td>
                <td class=[
                    "change",
                    {
                        profit: item.percentage_difference > 0,
                        loss: item.percentage_difference < 0,
                    },
                ]>
                    ${state.percentageFormatter.format(
                        item.percentage_difference / 100,
                    )}
                </td>
            </tr>
        </for>
    </table>
</if>

style {
    h2:first-of-type {
        margin-top: 3rem;
    }

    th.name,
    td.name {
        width: 40%;
    }

    th.value,
    td.value {
        width: 20%;
    }

    th.change,
    td.change {
        width: 20%;
    }

    @media (max-width: 1000px) {
        th.name,
        td.name {
            width: 50%;
        }

        th.value.last-year,
        td.value.last-year {
            display: none;
        }

        th.value.today,
        td.value.today {
            width: 25%;
        }

        th.change,
        td.change {
            width: 25%;
        }
    }

    @media (max-width: 800px) {
        th.name,
        td.name {
            width: 70%;
        }

        th.value,
        td.value {
            display: none;
        }

        th.change,
        td.change {
            white-space: nowrap;
            width: 30%;
        }
    }

    @media (max-width: 600px) {
        th.name,
        td.name {
            width: 60%;
        }

        th.value,
        td.value {
            display: none;
        }

        th.change,
        td.change {
            width: 40%;
        }
    }
}
