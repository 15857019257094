import Fuse from "fuse.js";
class {
    onCreate(input) {
        this.state = {
            suggestions: [],
            fuse: new Fuse(input.items, {
                keys: ["name"],
                threshold: 0.65,
                minMatchCharLength: 2,
            }),
            debounceTimer: null,
            searchText: "",
            waitingForAnimations: false,
            openAfterTransition: false,
            searchNoResults: false,
        };
    }

    listEventListener(e) {
        const listEl = e.target;
        const isEmpty = !this.state.searchText && listEl.children.length === 0;
        this.state.openAfterTransition = !isEmpty && e.propertyName === "height";
    }

    async noOngoingAnimations(el) {
        const animations = el.getAnimations();
        if(animations.length === 0) {
            return Promise.resolve();
        }
        await Promise.all(animations.map((animation) => animation.finished)).catch(() => {});
        return this.noOngoingAnimations(el);
    }

    async inputChanged(el) {
        this.state.searchText = el.target.value;
        if (this.state.debounceTimer) {
            clearTimeout(this.state.debounceTimer);
        }
        if(this.state.waitingForAnimations) {
            return;
        }
        if(!this.state.searchText) {
            this.state.suggestions = [];
            this.state.searchNoResults = false;
            return;
        }
        const listEl = document.querySelector("ul");
        const animations = listEl.getAnimations();
        if(animations.length > 0) {
            this.state.waitingForAnimations = true;
            console.time("fuse_wait_animations");
            await this.noOngoingAnimations(listEl);
            this.state.searchText = el.target.value;
            const suggestions = this.state.fuse.search(this.state.searchText, {
                limit: 15,
            });
            this.state.searchNoResults = suggestions.length === 0;
            this.state.suggestions = suggestions;
            this.state.waitingForAnimations = false;
            console.timeEnd("fuse_wait_animations");
        } else {
            this.state.debounceTimer = setTimeout(() => {
                console.time("fuse");
                this.state.searchText = el.target.value;
                const suggestions = this.state.fuse.search(this.state.searchText, {
                    limit: 15,
                });
                this.state.searchNoResults = suggestions.length === 0;
                this.state.suggestions = suggestions;
                console.timeEnd("fuse");
            }, 100);
        }
    }
}

<label for="search">Search CS2 items</label>
<input#search
    type="search"
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="false"
    on-input("inputChanged")
    value=state.searchText
    aria-label="Enter search term"
    name=input.name
    placeholder="Breakout Weapon Case"
    maxlength="60"
    required
>
<ul
    on-transitionend("listEventListener")
    style={ height: (state.suggestions.length > 0 ? state.suggestions.length * 32 : !!state.searchNoResults * 40) + 4 + "px" }
    class={ openAfterTransition: state.openAfterTransition }
>
    <if(state.searchNoResults)>
        <li.no-results >⚠️ No items found</li>
    </if>
    <else-if (state.suggestions.length > 0)>
        <for|row, index| of=state.suggestions>
            <li key=(row.item.id)>
                <a href=("/item/" + row.item.name)>
                    ${row.item.name}
                </a>
            </li>
        </for>
    </else-if>
</ul>
style {
    :root {
        --input-width: min(400px, 100%);
    }
    label {
        width: var(--input-width);
        margin-top: 3rem;
        padding: 10px 0;
    }
    input#search {
        width: var(--input-width);
        box-sizing: border-box;
        font-size: 24px;
        margin: 0 0 1rem 0;
        padding: 10px 0.75rem;
        border: 2px solid var(--dark-color);
        outline: none;
        transition: all 0.2s ease-in-out;
    }
    input:focus,
    input:hover,
    input:active {
        border: 2px solid var(--light-color);
    }
    ul {
        --animation-duration: 0.5s;
        --animation-duration-fifth: calc(var(--animation-duration) / 5);
        box-sizing: border-box;
        list-style: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        width: 0;
        opacity: 1;
        border: 2px solid transparent;
        transition-property: width, border, height;
        transition-delay: 0s, 0s, var(--animation-duration-fifth);
        transition-duration: var(--animation-duration-fifth),
            var(--animation-duration-fifth),
            calc(var(--animation-duration-fifth) * 4);
        flex-shrink: 0;
    }
    ul:not(:empty) {
        width: var(--input-width);
        opacity: 1;
        border: 2px solid var(--dark-color);
    }
    ul.openAfterTransition {
        transition-delay: calc(var(--animation-duration-fifth) * 4),
            calc(var(--animation-duration-fifth) * 4), 0s;
        transition-duration: var(--animation-duration-fifth),
            var(--animation-duration-fifth),
            calc(var(--animation-duration-fifth) * 4);
    }
    li {
        box-sizing: border-box;
        line-height: 1;
        height: 32px;
        display: grid;
        place-items: center start;
    }
    li a {
        color: var(--dark-color);
        text-decoration: none;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        padding: 0.5rem 0.75rem;
    }
    li a:hover {
        text-decoration: underline;
    }
    li.no-results {
        box-sizing: border-box;
        color: var(--dark-color);
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        padding: 0.75rem 0.5rem;
    }

    @media (max-width: 800px) {
        label,
        input#search {
            margin-left: 0;
            margin-right: 0;
        }

        ul {
            /* max 5 items (5 * 32px + 4px) */
            max-height: 164px;
        }
    }
}
